import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listarMedicoSolicitante = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_MEDICO_SOLICITANTE);
    return data
}

export const buscarMedicoSolicitante = async (page, payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_MEDICO_SOLICITANTE + `?page=${page}`, payload);
    return data
}

export const editarMedicoSolicitante = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_MEDICO_SOLICITANTE + `/${id}`, payload);
    return data
}

export const exibitMedicoSolicitante = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_MEDICO_SOLICITANTE + `/${id}`);
    return data
}

export const excluirMedicoSolicitante = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_MEDICO_SOLICITANTE + `/${id}`);
    return data
}

export const criarMedicoSolicitante = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_MEDICO_SOLICITANTE, payload);
    return data;
};